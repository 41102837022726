<template>
    <div class="philosophy full-width" v-if="translates && translates[langUrl]">
        <div
            class="philosophy__main-image">
            <div class="philosophy__main-image__opacity"></div>
            <div class="philosophy__main-image__container">
                <div class="philosophy__main-image__container__text">
                    <div class="philosophy__main-image__container__title">{{ translates[langUrl].pageTitle[lang] }}</div>
                </div>
            </div>
        </div>
        
        <div class="philosophy__main-container">
            <div class="philosophy__main-container__title">{{ translates[langUrl].block1_title[lang] }}</div>
            <div class="philosophy__block philosophy__block__second">
                <div class="philosophy__block__text-block" v-if="isDesktop">
                    <div class="philosophy__block__text-block__description philosophy__second-block__text-block__description">{{ translates[langUrl].block1_item1_text[lang] }}</div>
                </div>
                <div class="philosophy__block__picture philosophy__block__picture__1"></div>
                <div class="philosophy__block__text-block" v-if="!isDesktop">
                    <div class="philosophy__block__text-block__description philosophy__second-block__text-block__description">{{ translates[langUrl].block1_item1_text[lang] }}</div>
                </div>
            </div>
            <div class="philosophy__block philosophy__block__second">
                <div class="philosophy__block__text-block">
                    <div v-if="isDesktop" class="philosophy__block__text-block__text">
                        <div class="philosophy__block__text-block__description philosophy__second-block__text-block__description" v-html="translates[langUrl].block1_item2_text[lang]"></div>
                    </div>
                </div>
                <div class="philosophy__block__picture philosophy__block__picture__2"></div>
                <div v-if="!isDesktop" class="philosophy__block__text-block__text philosophy__block__text-block__text-first">
                    <div v-if="!isDesktop" class="philosophy__block__text-block__description philosophy__second-block__text-block__description" v-html="translates[langUrl].block1_item2_text[lang]"></div>
                </div>
            </div>
            
        </div>
        <div class="philosophy__rules">
            <div class="philosophy__rules__title">{{ translates[langUrl].rules_blockTitle[lang] }}</div>
        </div>
        <div class="philosophy__second-block">
            <div class="philosophy__second-block__left-block">
                <div class="philosophy__block__text-block philosophy__second-block__text-block">
                    <div v-if="isDesktop" class="philosophy__block__text-block__text philosophy__second-block__text-block__text">
                        <div class="philosophy__block__text-block__title philosophy__second-block__text-block__title">{{ translates[langUrl].rules_item1_title[lang] }}</div>
                        <div class="philosophy__block__text-block__description philosophy__second-block__text-block__description" v-html="translates[langUrl].rules_item1_description[lang]"></div>
                    </div>
                </div>
                <div class="philosophy__block__picture philosophy__second-block__left-block__picture_1"></div>
                <div class="philosophy__block__text-block philosophy__second-block__text-block">
                    <div v-if="isDesktop" class="philosophy__block__text-block__text philosophy__second-block__text-block__text">
                        <div class="philosophy__block__text-block__title philosophy__second-block__text-block__title">{{ translates[langUrl].rules_item2_title[lang] }}</div>
                        <div class="philosophy__block__text-block__description philosophy__second-block__text-block__description" v-html="translates[langUrl].rules_item2_description[lang]"></div>
                    </div>
                </div>
                <div class="philosophy__block__picture philosophy__second-block__left-block__picture_2"></div>
                <div class="philosophy__block__text-block philosophy__second-block__text-block">
                    <div v-if="isDesktop" class="philosophy__block__text-block__text philosophy__second-block__text-block__text">
                        <div class="philosophy__block__text-block__title philosophy__second-block__text-block__title">{{ translates[langUrl].rules_item4_title[lang] }}</div>
                        <div class="philosophy__block__text-block__description philosophy__second-block__text-block__description" v-html="translates[langUrl].rules_item4_description[lang]"></div>
                    </div>
                </div>
                <div class="philosophy__block__picture philosophy__second-block__left-block__picture_3"></div>
                <div class="philosophy__block__text-block philosophy__second-block__text-block">
                    <div v-if="isDesktop" class="philosophy__block__text-block__text philosophy__second-block__text-block__text">
                        <div class="philosophy__block__text-block__title philosophy__second-block__text-block__title">{{ translates[langUrl].rules_item9_title[lang] }}</div>
                        <div class="philosophy__block__text-block__description philosophy__second-block__text-block__description" v-html="translates[langUrl].rules_item9_description[lang]"></div>
                    </div>
                </div>
                <div class="philosophy__block__picture philosophy__second-block__left-block__picture_4"></div>
                <div class="philosophy__block__text-block philosophy__second-block__text-block">
                    <div v-if="isDesktop" class="philosophy__block__text-block__text philosophy__second-block__text-block__text">
                        <div class="philosophy__block__text-block__title philosophy__second-block__text-block__title">{{ translates[langUrl].rules_item10_title[lang] }}</div>
                        <div class="philosophy__block__text-block__description philosophy__second-block__text-block__description" v-html="translates[langUrl].rules_item10_description[lang]"></div>
                    </div>
                </div>
            </div>
            <div class="philosophy__second-block__right-block">
                <div class="philosophy__block__picture philosophy__second-block__right-block__picture_5"></div>
                <div class="philosophy__block__picture philosophy__second-block__right-block__picture_6"></div>
                <div class="philosophy__block__text-block philosophy__second-block__text-block">
                    <div v-if="isDesktop" class="philosophy__block__text-block__text philosophy__second-block__text-block__text">
                        <div class="philosophy__block__text-block__title philosophy__second-block__text-block__title">{{ translates[langUrl].rules_item3_title[lang] }}</div>
                        <div class="philosophy__block__text-block__description philosophy__second-block__text-block__description" v-html="translates[langUrl].rules_item3_description[lang]"></div>
                    </div>
                </div>
                <div class="philosophy__block__text-block philosophy__second-block__text-block">
                    <div v-if="isDesktop" class="philosophy__block__text-block__text philosophy__second-block__text-block__text">
                        <div class="philosophy__block__text-block__title philosophy__second-block__text-block__title">{{ translates[langUrl].rules_item5_title[lang] }}</div>
                        <div class="philosophy__block__text-block__description philosophy__second-block__text-block__description" v-html="translates[langUrl].rules_item5_description[lang]"></div>
                    </div>
                </div>
                <div class="philosophy__block__picture philosophy__second-block__right-block__picture_7"></div>
                <div class="philosophy__block__text-block philosophy__second-block__text-block">
                    <div v-if="isDesktop" class="philosophy__block__text-block__text philosophy__second-block__text-block__text">
                        <div class="philosophy__block__text-block__title philosophy__second-block__text-block__title">{{ translates[langUrl].rules_item6_title[lang] }}</div>
                        <div class="philosophy__block__text-block__description philosophy__second-block__text-block__description" v-html="translates[langUrl].rules_item6_description[lang]"></div>
                    </div>
                </div>
                <div class="philosophy__block__text-block philosophy__second-block__text-block">
                    <div v-if="isDesktop" class="philosophy__block__text-block__text philosophy__second-block__text-block__text">
                        <div class="philosophy__block__text-block__title philosophy__second-block__text-block__title">{{ translates[langUrl].rules_item7_title[lang] }}</div>
                        <div class="philosophy__block__text-block__description philosophy__second-block__text-block__description" v-html="translates[langUrl].rules_item7_description[lang]"></div>
                    </div>
                </div>
                <div class="philosophy__block__text-block philosophy__second-block__text-block">
                    <div v-if="isDesktop" class="philosophy__block__text-block__text philosophy__second-block__text-block__text">
                        <div class="philosophy__block__text-block__title philosophy__second-block__text-block__title">{{ translates[langUrl].rules_item8_title[lang] }}</div>
                        <div class="philosophy__block__text-block__description philosophy__second-block__text-block__description" v-html="translates[langUrl].rules_item8_description[lang]"></div>
                    </div>
                </div>
                <div class="philosophy__block__picture philosophy__second-block__right-block__picture_8"></div>
                <div class="philosophy__block__text-block philosophy__second-block__text-block">
                    <div v-if="isDesktop" class="philosophy__block__text-block__text philosophy__second-block__text-block__text">
                        <div class="philosophy__block__text-block__title philosophy__second-block__text-block__title">{{ translates[langUrl].rules_item11_title[lang] }}</div>
                        <div class="philosophy__block__text-block__description philosophy__second-block__text-block__description" v-html="translates[langUrl].rules_item11_description[lang]"></div>
                    </div>
                </div>
                <div class="philosophy__block__picture philosophy__second-block__right-block__picture_9"></div>
            </div>
        </div>
        <div class="philosophy__footer"></div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { isMobile, imageSrc, SplideSlideCounter } from '@/helpers';
    import { Splide, SplideSlide } from '@splidejs/vue-splide';
    import { TRIP_STATUS } from '@/vars';

    import BaseButton from '../components/common/BaseButton.vue';
    import MobilePreview from '../components/common/trip/PreviewCard';

    import moment from '@/plugins/moment';
    moment.locale('ru');

    export default {
        name: 'Philosophy',
        metaInfo() {
            return {
                title: this.translates[this.langUrl].browserTitle ? this.translates[this.langUrl].browserTitle[this.lang]: ''
            }
        },
        components: {
            BaseButton,
            Splide,
            SplideSlide,
            MobilePreview
        },
        data: () => ({
            timerDate: '',
            timer: null,
            isDesktop: false,
            imageSrc,
            isHosting: true,
            langUrl: '/philosophy'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('auth', ['user']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
        },
        created() {
            this.isDesktop = !isMobile();
        },
        methods: {
            toggleDialogUserType(e) {
                this.$store.commit('setDialogUserType', !this.showDialogUserType);
            },
            async authorization() {
                this.$root.$emit('popupLoginShow');
            },
            async goTo(name) {
                await this.$router.push({ name });
            },
            hosting() {
                this.isHosting = true
            },
            notHosting() {
                this.isHosting = false
            }
        },
    };
</script>

<style lang="scss">
.philosophy {
    width: 100%;
    &__main-image {
        position: relative;
        width: 100%;
        height: 440px;
        background: url(../assets/philosophy/header.jpg) no-repeat;
        background-size: cover;
        background-position: center;
        margin-bottom: 80px;
        @media all and (max-width: 768px) {
            height: 356px;
            background-position: center;
            margin-bottom: 60px;
        }
        // &__opacity {
        //     position: absolute;
        //     width: 100%;
        //     height: 100%;
        //     background: #543919;
        //     opacity: 0.5;
        // }
        &__container {
        position: absolute;
        width: 100%;
        height: 440px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
            @media all and (max-width: 768px) {
                height: 254px;
            }
            &__text {
                display: flex;
                flex-direction: column;
                width: 1160px;
                @media all and (max-width: 768px) {
                    width: 100%;
                    padding: 20px;
                    margin: 0 auto;
                }
            }
            &__title {
                font-weight: bold;
                font-size: 56px;
                // margin-top: 245px;
                line-height: 150%;
                letter-spacing: -0.02em;
                text-align: center;
                @media all and (max-width: 768px) {
                    font-weight: 800;
                    font-size: 24px;
                    margin-top: 115px;
                    margin-bottom: 20px;
                }
            }
        }
    }
    &__main-container {
        max-width: 1280px;
        margin: 0 auto;
        &__title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 38px;
            line-height: 46px;
            text-align: left;
            color: #273155;
            margin-bottom: 80px;
            @media all and (max-width: 768px) {
                padding: 0 20px;
                font-size: 28px;
                line-height: 34px;
                margin-bottom: 40px;
            }
        }
        &__second {
            padding: 80px 0;
            background: #F6F7F9;
            @media all and (max-width: 768px) {
                padding: 60px 0 20px;
            }
            &__title {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 38px;
                line-height: 46px;
                text-align: center;
                color: #273155;
                margin-bottom: 80px;
                @media all and (max-width: 768px) {
                    font-size: 28px;
                    line-height: 34px;
                    margin-bottom: 60px;
                }
            }
            &__container {
                max-width: 1280px;
                margin: 0 auto;
                @media all and (max-width: 768px) {
                    display: flex;
                    flex-direction: column;
                }
            }
        }
        &__button {
            margin-left: calc(50% - 97px);
            @media all and (max-width: 768px) {
                margin: 0 auto;
            }
        }
    }
    &__block {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 80px;
        &:nth-of-type(2n) {
            flex-direction: row-reverse;
        }
        &__second {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            margin-bottom: 80px;
            &:nth-of-type(2n) {
                display: flex;
                flex-direction: row;
            }
        }
        @media all and (max-width: 768px) {
            flex-direction: column;
            margin-bottom: 50px;
            &:nth-of-type(2n) {
                flex-direction: column;
            }
        }
        &__text-block {
            display: flex;
            flex-direction: row;
            max-width: 500px;

            @media all and (max-width: 768px) {
                width: 100%;
                padding: 0 20px;
                flex-direction: column;
            }
            &__text {
                display: flex;
                flex-direction: column;
                font-family: 'Inter';
                font-style: normal;
                margin: 0 20px;
                &-first{
                    @media all and (max-width: 768px) {
                        width: calc(100% - 40px);
                    }
                }
            }
            &__title {
                font-weight: 700;
                font-size: 38px;
                line-height: 46px;
                color: #273155;
                margin-bottom: 32px;
                @media all and (max-width: 768px) {
                    font-size: 28px;
                    line-height: 130%;
                }
            }
            &__description {
                font-weight: 400;
                font-size: 18px;
                line-height: 180%;
                color: #273155;
                margin-bottom: 32px;
                @media all and (max-width: 768px) {
                    font-size: 16px;
                    line-height: 140%;
                }
            }
            ul > li {
                font-weight: 400;
                font-size: 18px;
                line-height: 160%;
                color: #3A4256;
                margin-bottom: 16px;
                list-style: none;
                &:before {
                    content: '✔';
                    color: #8ACCDF;
                    margin: 0 10px 0 -24px;
                }
            }
            &__button {
                width: 239px;
                height: 52px;
                margin: 0 auto 0 0;
                @media all and (max-width: 768px) {
                    margin: 0 auto 40px;
                    width: calc(100% - 40px);
                }
            }
        }
        &__button {
            margin: 0 auto 0 0;
        }
        &__picture {
            min-width: 600px;
            max-width: 600px;
            height: 430px;
            background-size: cover;
            background-position: center;
            border-radius: 10px;
            position: relative;
            @media all and (max-width: 1200px) {
                min-width: 500px;
                height: 355px;
            }
            @media all and (max-width: 950px) {
                min-width: 400px;
                height: 290px;
            }
            @media all and (max-width: 768px) {
                width: calc(100% - 40px);
                height: 220px;
                margin-bottom: 40px;
            }
            &__1 {
                background-image: url(../assets/philosophy/1.jpg);
                margin-bottom: 0;
                border-radius: 10px 10px 0 0;
                @media all and (max-width: 768px) {
                    margin-bottom: 20px;
                    border-radius: 10px;
                }
            }
            &__2 {
                background-image: url(../assets/philosophy/2.jpg);
            }
            &__3 {
                background-image: url(../assets/benefits/3.jpg);
            }
            &__4 {
                background-image: url(../assets/benefits/4.jpg);
            }
            &__5 {
                background-image: url(../assets/benefits/5.jpg);
            }
            &-text-block {
                position: absolute;
                right: -90px;
                top: 60px;
                width: 400px;
                background: #FFFFFF;
                box-shadow: 0px 4px 22px rgba(44, 47, 56, 0.05);
                border-radius: 12px;
                padding: 28px;
                @media all and (max-width: 1420px) {
                    right: 20px;
                }
                &_mobile {
                    box-shadow: 0px 4px 22px rgba(44, 47, 56, 0.05);
                    border-radius: 0 0 12px 12px;
                    margin-bottom: 60px;
                    padding: 20px;
                    margin: 0 20px;
                }
                &__text {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 180%;
                    color: #616D89;
                    @media all and (max-width: 768px) {
                        font-size: 16px;
                        line-height: 140%;
                    }
                }
            }
        }
    }
    &__rules {
        max-width: 1280px;
        margin: 80px auto 170px;
        @media all and (max-width: 768px) {
            margin: 60px auto;
            padding: 0 20px;
        }
        &__title {
            background: #F6F7F9;
            border-radius: 12px;
            padding: 32px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 140%;
            color: #273155;
            margin-bottom: 40px;
            @media all and (max-width: 768px) {
                font-size: 16px;
            }
        }
    }
    &__second-block {
        display: flex;
        flex-direction: row;
        &__text-block {
            max-width: 565px;
            width: 565px;
            margin-bottom: 100px;
            &__text{
                margin: 0;
            }
            &__title {
                font-weight: 600;
                font-size: 28px;
                line-height: 140%;
                margin-bottom: 24px;
            }
            &__description {
                margin-bottom: 0;
                font-weight: 400;
                font-size: 18px;
                line-height: 180%;
            }
        }
        &__left-block {
            display: flex;
            flex-direction: column;
            width: 50%;
            padding-right: 50px;
            align-items: flex-end;
            &__picture_1 {
                background-image: url(../assets/philosophy/4.jpg);
                height: 470px;
                background-size: cover;
                margin-bottom: 100px;
            }
            &__picture_2 {
                background-image: url(../assets/philosophy/12.jpg);
                height: 331px;
                margin-bottom: 100px;
            }
            &__picture_3 {
                background-image: url(../assets/philosophy/13.jpg);
                height: 758px;
                margin-bottom: 100px;
            }
            &__picture_4 {
                background-image: url(../assets/philosophy/10.jpg);
                height: 470px;
                margin-bottom: 100px;
            }
        }

        &__right-block {
            width: 50%;
            align-items: left;
            padding-left: 50px;
            &__picture_5 {
                background-image: url(../assets/philosophy/3.jpg);
                height: 415px;
                margin-bottom: 100px;
            }
            &__picture_6 {
                background-image: url(../assets/philosophy/5.png);
                height: 758px;
                margin-bottom: 100px;
            }
            &__picture_7 {
                background-image: url(../assets/philosophy/14.jpg);
                height: 280px;
                margin-bottom: 100px;
            }
            &__picture_8 {
                background-image: url(../assets/philosophy/9.jpg);
                height: 380px;
                margin-bottom: 100px;
            }
            &__picture_9 {
                background-image: url(../assets/philosophy/11.jpg);
                height: 280px;
                margin-bottom: 100px;
            }
        }
    }
    &__footer {
        height: 550px;
        width: 100%;
        background-image: url(../assets/philosophy/footer.png);
    }
}

</style>